// Here you can add other styles
.invoice-box {
  max-width: inherit;
  // margin: auto;
  margin-top: 10px;
  // padding: 10px;
  // font-size: 16px;
  line-height: 17px;
  // font-family: LouisGeorge;
  color: #555;
}

.invoice-box table {
  table-layout: fixed;
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr td:nth-child(1) {
  border: 0;
  font: inherit;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  // font-size: 45px;
  // line-height: 45px;
  color: #333;
}

.invoice-box table tr.top table td.title .statusSuccess {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  // font-size: 16px;
  padding: 9px;
  line-height: 20px;
  color: #34a263;
  margin-top: 5px;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  border: 1px solid transparent;
  font-weight: 600;
}

.invoice-box table tr.top table td.title .statusFailed {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  // font-size: 16px;
  padding: 9px;
  line-height: 20px;
  color: #a94442;
  margin-top: 5px;
  background-color: #f2dede;
  border-color: #ebccd1;
  border: 1px solid transparent;
  font-weight: 600;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  // border-top: 2px solid #eee;
  font-weight: bold;
  padding-right: 150px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.top table td.title tr.titleType {
    // font-size: 40px;
    // line-height: 40px;
    display: block;
    color: #333;
  }

  .invoice-box table tr.total {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column wrap;
    align-items: stretch;
    width: 600%;
    // width: 350px;
    // min-width: 350px;
  }

  .invoice-box table tr.total td:nth-child(1) {
    order: 1;
    flex: 0 0 100%;
    margin-top: 4px;
  }

  .invoice-box table tr.total td:nth-child(2) {
    align-self: flex-end;
    padding-right: 0px;
    flex: 0 0 100%;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

@page {
  size: A4;
  margin: 1.5rem;
}

@media all {
  .page-break {
    display: none;
  }
}

@media screen {
  .no-screen {
    display: none;
  }
}

@media print {
  html,
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    background: white;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
}
