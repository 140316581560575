.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  height: 33px;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}

h6,
td {
  white-space: pre-line;
}

.date-range-table {
  background: #fff;
  // color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-size: 90%;
  border-radius: 5px !important;
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-color: #d8dbe0;
  z-index: 9999;
  overflow: hidden !important;
}

.date-range-table-btn {
  box-shadow: unset !important;
  padding: 0% !important;
}
